
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

interface IValidator {
  apy: number,
  validator: string,
  commission: number,
  name: string,
  proportion: number,
  totalVoteNum: number,
  status: number,
}

@Component({
  apollo: {
    allValidators: {
      query: require("../../apollo/graphql/Validators.graphql"),
    },
  },
})
export default class ValidatorsPage extends Vue {

  public allValidators: IValidator[] = [];
  public sortedValidators: IValidator[] = [];

  private voteFor(item: IValidator) {
    this.$router.push(`/addVote/${item.validator}`);
  }

  @Watch("allValidators")
  validatorsChange(value: IValidator[]) {
    const res = [
      ...value.filter((item) => item.status === 1),
      ...value.filter((item) => item.status === 2),
    ];
    console.log(res, 'res');
    this.sortedValidators = res;
  }
}
